import { Box, Grid, Stack, Typography } from '@mui/material';
import { MatrixDto, MatrixItem } from '@shared/src/api/matrix/dto/matrix.dto';
import { ScatterPointShape } from '@shared/src/api/pillier/dto/pillier.dto';
import { blues, greens, pinks } from '@shared/src/assets/colors/Colors';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CustomTooltip = (props: any) => {
  const { lang } = useLang();
  if (props.payload?.length) {
    return (
      <Box className="custom-tooltip" sx={{ fontSize: '12px', backgroundColor: 'white', py: 0.1, px: 1 }}>
        <p>{props.payload[0].payload.label[lang]}</p>
        <p className="label">{`${props.payload[0].name} : ${+parseFloat(
          props.payload[0].payload.outsideIn.toFixed(1),
        )}`}</p>
        <p className="label">{`${props.payload[1].name} : ${+parseFloat(
          props.payload[1].payload.insideOut.toFixed(1),
        )}`}</p>
      </Box>
    );
  }

  return null;
};

const GetTickLabel = (value: number) => {
  const intl = useIntl();
  const authorizedTickValues: number[] = [1, 2, 3, 4];
  return authorizedTickValues.includes(value) ? intl.formatMessage({ id: `matrix.chart.tick${value}` }) : '';
};

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const tickLabel: string = GetTickLabel(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={7} textAnchor="middle" fill="#666" fontSize="11px">
        {tickLabel}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const tickLabel: string = GetTickLabel(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={-7} textAnchor="middle" fill="#666" fontSize="11px" transform="rotate(-90)">
        {tickLabel}
      </text>
    </g>
  );
};

type LegendElementProps = { shape: ScatterPointShape; name: string; color: string };

const LegendElement = ({ shape, color, name }: LegendElementProps) => (
  <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'left' }}>
    <li style={{ display: 'block', marginRight: '10px' }}>
      <svg
        width="32"
        height="16"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '4px',
        }}
      >
        {shape === 'triangle' && <polygon points="0,16 8,0 16,16" fill={color} />}
        {shape === 'circle' && <circle cx="22" cy="8" r="8" fill={color} />}
        {shape === 'square' && <rect x="0" y="0" width="16" height="16" fill={color} />}
      </svg>
      <span>{name}</span>
    </li>
  </ul>
);

const renderLegend = (props: any) => {
  const { payload } = props;

  // Add colors of piliers to the list of subjects' colors
  const greensColors = greens;
  const bluesColors = blues;
  const pinksColors = pinks;
  greensColors.push('green');
  bluesColors.push('blue');
  pinksColors.push('#ff477e');

  // Create one list for each pilier
  const greenList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];
  const blueList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];
  const pinkList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];

  payload.forEach((el: any) => {
    const green = greensColors.findIndex((item) => item === el.color);
    const blue = bluesColors.findIndex((item) => item === el.color);
    const pink = pinksColors.findIndex((item) => item === el.color);
    if (green > -1) {
      greenList.push({ color: el.color, name: el.payload.name, order: green, shape: 'triangle' });
    }
    if (blue > -1) {
      blueList.push({ color: el.color, name: el.payload.name, order: blue, shape: 'square' });
    }
    if (pink > -1) {
      pinkList.push({ color: el.color, name: el.payload.name, order: pink, shape: 'circle' });
    }
  });

  // Sort list element by color intensity
  greenList.sort((a, b) => b.order - a.order);
  pinkList.sort((a, b) => b.order - a.order);
  blueList.sort((a, b) => b.order - a.order);

  const todayDate = new Date().toLocaleDateString();

  return (
    <Stack>
      <Grid container spacing={2} width="900px">
        <Grid item xs={4}>
          {greenList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-environnement-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>

        <Grid item xs={4}>
          {pinkList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-social-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>

        <Grid item xs={4}>
          {blueList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-governance-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>
      </Grid>
      <Typography variant="body2" marginTop="2rem" textAlign="left" fontStyle="italic">
        <FormattedMessage id="matrix.date" /> {todayDate}
      </Typography>
    </Stack>
  );
};

export function Matrix(props: MatrixDto) {
  const { items, pilliers } = props;
  const intl = useIntl();
  const { lang } = useLang();
  // points avec positions recalculées pour éviter le chevauchement des points
  const [points, setPoints] = useState<MatrixItem[]>([]);

  const itemsByPillier = (pillierId: string) => {
    return points.filter((item) => item.pillierId === pillierId);
  };

  const adjustPointPositions = useCallback((points: MatrixItem[], currentIndex: number = 0): MatrixItem[] => {
    const adjustedPoints: MatrixItem[] = [...points];
    const pointToAdjust = adjustedPoints[currentIndex];

    // Vérifier si le point a des coordonnées en conflit avec d'autres points
    const conflicts = adjustedPoints.some(
      (existingPoint, index) =>
        index !== currentIndex && existingPoint.x === pointToAdjust.x && existingPoint.y === pointToAdjust.y,
    );

    // Si un conflit est détecté, ajuster les coordonnées du point
    if (conflicts) {
      if (pointToAdjust.x >= 4) {
        pointToAdjust.y += 0.06;
      } else {
        pointToAdjust.x += 0.03;
      }
      return adjustPointPositions(adjustedPoints, currentIndex); // Rappeler la fonction pour vérifier à nouveau
    }

    if (currentIndex < adjustedPoints.length - 1) {
      return adjustPointPositions(adjustedPoints, currentIndex + 1); // Passer au point suivant
    }

    return adjustedPoints;
  }, []);

  useEffect(
    function () {
      const adjustedPoints = adjustPointPositions(items);
      setPoints(adjustedPoints);
    },
    [adjustPointPositions, items],
  );

  function getLabelPosition(
    x: number,
    y: number,
    name: string,
    witdh: number,
    heigt: number,
  ): { x: number; y: number } {
    const adjustedPoint = { x, y };
    const xAdjustment: number = -8;
    const yAdjustment: number = -10;
    adjustOnBordures(adjustedPoint);
    labelsPositions.forEach((point, label) => {
      if (
        label !== name &&
        zonesSeSuperposent(adjustedPoint.x, adjustedPoint.y, point[0], point[1], witdh, point[2], heigt, point[3])
      ) {
        // on décale sur l'axe y
        const { x: newX, y: newY } = getLabelPosition(
          adjustedPoint.x + xAdjustment,
          adjustedPoint.y + yAdjustment,
          name,
          witdh,
          heigt,
        );

        adjustedPoint.y = newY;
        adjustedPoint.x = newX;
      }
    });
    return { x: adjustedPoint.x, y: adjustedPoint.y || y };
  }

  function adjustOnBordures(adjustedPoint: { x: number; y: number }) {
    if (adjustedPoint.x <= 141) {
      // label sur l'axe des ordonnées => on décale
      adjustedPoint.x += 40;
    } else if (adjustedPoint.x >= 1379) {
      // label sur la bordure de droite => on décale
      adjustedPoint.x -= 45;
    } else if (adjustedPoint.y < 40) {
      // label sur la bordure haute
      if (adjustedPoint.x <= 700) {
        // proche de la gauche => on décale à droite
        adjustedPoint.x += 20;
      } else {
        // proche de la droite => on décale à gauche
        adjustedPoint.x -= 20;
      }
    }
  }

  function zonesSeSuperposent(
    ax: number,
    ay: number,
    bx: number,
    by: number,
    awidth: number,
    bwidth: number,
    aheigt: number,
    bheigt: number,
  ): boolean {
    // Calcul des coordonnées des bords des deux zones
    const maxaX = ax + awidth;
    const maxaY = ay + aheigt;
    const maxbX = bx + bwidth;
    const maxbY = by + bheigt;

    return maxaX >= bx && ax <= maxbX && ay <= maxbY && maxaY >= by;
  }

  // tableau des vraies coordonnées des labels
  let labelsPositions = new Map<string, number[]>();

  function splitStringEquallyIn2Lines(str: string): string[] {
    const words = str.split(' ');
    const numWordsPerLine = Math.ceil(words.length / 2);
    const lines: string[] = [];

    for (let i = 0; i < 2; i++) {
      const start = i * numWordsPerLine;
      const end = start + numWordsPerLine;
      const lineWords = words.slice(start, end);
      if (lineWords.length !== 0) {
        lines.push(lineWords.join(' '));
      }
    }

    return lines;
  }

  const renderCustomizedLabel = (props: any) => {
    const { x, y, value, width, fill } = props;
    const lines = splitStringEquallyIn2Lines(value[lang] ?? '');
    let lineWidthMax = 0;
    let lineHeigtMax = 15;
    for (let i = 0; i < lines.length; i++) {
      const lineWidth = lines[i].length * 7.5;
      if (lineWidth > lineWidthMax) lineWidthMax = lineWidth;
      if (i === 1) {
        lineHeigtMax = 30;
      }
    }
    const tailleDemiPoint = width / 2;
    const { x: newX, y: newY } = getLabelPosition(
      x + tailleDemiPoint,
      y - lineHeigtMax - width,
      value,
      lineWidthMax,
      lineHeigtMax,
    );
    labelsPositions.set(value, [newX, newY, lineWidthMax, lineHeigtMax]);

    return (
      <g className="matrix-label">
        <line
          x1={x + tailleDemiPoint}
          y1={y + tailleDemiPoint}
          x2={newX}
          y2={newY + 17 * lines.length}
          strokeWidth="1"
          stroke={fill}
          strokeDasharray="3,3"
        />
        <text x={newX} y={newY} width={width} textAnchor="middle" dominantBaseline="middle" fontSize={12}>
          {lines.map((line: string, index) => (
            <tspan key={index} x={newX} dy="1em">
              {line}
            </tspan>
          ))}
        </text>
      </g>
    );
  };

  return (
    <Stack id="matrix-and-legend" style={{ paddingBottom: '3rem' }}>
      <ResponsiveContainer width="100%" height={800}>
        <ScatterChart
          width={1200}
          margin={{
            top: 62,
            right: 70,
            bottom: 10,
            left: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            domain={[0, 4]}
            tickCount={5}
            tick={<CustomizedXAxisTick />}
            tickMargin={5}
            tickSize={5}
            dataKey="x"
            type="number"
            name="Outside in"
            axisLine={{ stroke: '#A57B48' }}
            label={{
              value: intl.formatMessage({ id: 'matrix.chart.xaxis' }),
              position: 'insideBottomRight',
              offset: '-5',
              dy: 10,
              fontWeight: '800',
            }}
          />
          <YAxis
            domain={[0, 4]}
            tickCount={5}
            tick={<CustomizedYAxisTick />}
            tickSize={5}
            dataKey="y"
            type="number"
            name="Inside out"
            axisLine={{ stroke: '#A57B48' }}
            label={{
              value: intl.formatMessage({ id: 'matrix.chart.yaxis' }),
              angle: -90,
              position: 'top',
              offset: '-90',
              dx: -15,
              fontWeight: '800',
            }}
          />
          {/* <ZAxis dataKey="z" type="number" range={[0, 100]} name="score" /> */}
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
          <ReferenceArea x1={2} x2={4} y1={2} y2={4} fill="#A57B48" fillOpacity={0.35} />
          <ReferenceArea x1={0} x2={2} y1={2} y2={4} fill="#A57B48" fillOpacity={0.2} />
          <ReferenceArea x1={2} x2={4} y1={0} y2={2} fill="#A57B48" fillOpacity={0.2} />
          <Legend
            layout="vertical"
            content={renderLegend}
            wrapperStyle={{ fontSize: '12px', position: 'absolute', bottom: '-2rem', left: '100px' }}
          />
          {pilliers.map((pillier) => (
            <Scatter
              key={pillier.id}
              name={pillier.nom[lang] ?? ''}
              data={itemsByPillier(pillier.id)}
              fill={pillier.couleur}
              shape={pillier?.shape ?? 'star'}
            >
              <LabelList dataKey="label" position="top" fontSize={'0.7rem'} content={renderCustomizedLabel} />
            </Scatter>
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </Stack>
  );
}
